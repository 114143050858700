
import { Preferences } from '@capacitor/preferences';
import { Browser } from '@capacitor/browser';
import { loadingController } from '@ionic/vue';
import { computed, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import router from '../../router';
import store from '@/store';
import settings from '@/WiseSettings.js'
import useGolfState from './GolfState';
import { usePushNotifications } from '../../helpers/usePushNotifications';
import { _locale } from '../services/WiseLocale';
import { wiseAlert } from '../services/WiseAlert';

export default function useWiseGolf() {
    const { notificationsSettings } = usePushNotifications()
    const route = useRoute()
    const {
        selectedClub,
        associatedClubs,
        setSelectedClub,
    } = useGolfState()

    const loggedIn = computed(() => store.getters['user/loggedIn'])
    const selectedHost = computed(() => store.getters['common/selectedHost'])
    const adminMode = computed(() => store.getters['common/adminMode'])
    const serviceSettings = computed(() => ({
        baseUrl: store.getters['common/baseUrl'],
        restUrl: store.getters['common/restUrl'],
        ecomUrl: store.getters['common/ecomUrl'],
        ajaxUrl: store.getters['common/ajaxUrl'],
        adminUrl: store.getters['common/adminUrl'],
        appauth: store.getters['common/appauth'],
    }))

    watch(loggedIn, (val) => {
        if (!val) {
            Array.from(document.body.classList)
            .forEach(row => {
                if (row.includes('golfclub-')) {
                    document.body.classList.remove(row)
                }
            })
        }
    })

    let consentLoading = null

    async function loadingOverlay() {
        consentLoading = await loadingController.create({
            cssClass: 'wise-login-overlay',
            message: _locale('login.checkConsent'),
        })
        await consentLoading.present()
    }

    async function getClubMemory() {
        const { value } = await Preferences.get({ key: `selectedGolfClub-${ settings.appId }` });

        try {
            return JSON.parse(value);
        } catch(e) {
            // console.warn(e)
        }
        
        return null;
    }

    function setClubMemory(club) {
        Preferences.set({ key: `selectedGolfClub-${ settings.appId }`, value: JSON.stringify(club) })
    }

    async function ecomCartAlert(data) {
        if (route.path.includes('checkout')) {
            return false;
        }

        const { dispatch } = store;
        wiseAlert({
            mode: 'ios',
            message: _locale('ecom.cartHasReservation'),
            buttons: [
                {
                    text: _locale(data.orderId ? 'ecom.cartCancelReservation' : 'ecom.cartContinueShopping'),
                    handler: () => {
                        if (data.orderId) {
                            dispatch('res_ecom/deleteCartOrder')
                            .catch((error) => {
                                wiseAlert({
                                    mode: 'ios',
                                    message: error,
                                    buttons: ['OK'],
                                })
                            })
                            /*.finally(() => {
                                location.reload();
                            })*/
                        }
                    },
                },
                {
                    text: _locale('Proceed to checkout'),
                    handler: () => {
                        // redirect to checkout
                        router.push('/golf/checkout')
                    },
                },
            ],
        })
    }

    async function checkConsent() {
        const [ selected ] = associatedClubs.value.filter(row => row.name === selectedClub.value)

        if (!selected) return;

        const { appauth, urls, name } = selected;

        if (selected.name === selectedHost.value) {
            return;
        }

        let checkConsent = true;
        // force consent for user :--D
        if (window.forceGolfConsent) {
            checkConsent = false;

            delete window.forceGolfConsent;
        }
        
        const { success, personId, userId } = await store.dispatch(
            'user/consentUser', 
            { checkConsent, name, appauth, ...urls }
        )

        if (success) {
            store.commit('user/mutateOverrideHostUser', { personId, userId })
        } else {
            const [ defaultOwner ] = associatedClubs.value.filter(row => row.name === selectedHost.value)

            selectedClub.value = defaultOwner?.name;
        }
    }

    async function onClubSelection(save = true) {
        const { dispatch, commit } = store;
        const [ selected ] = associatedClubs.value.filter(row => row.name === selectedClub.value)

        if (!selected) return;
        
        if (save) {
            setClubMemory(selected.name);
        }
        
        if (selected.name !== selectedHost.value) {
            const { appauth, urls, name, articleCategory } = selected;

            commit('common/mutateOverrideHost', { name, appauth, articleCategory, ...urls })
            dispatch('user/updateUserSettings', notificationsSettings.value)
        } else {
            commit('common/mutateOverrideHost', null);
        }

        const [ bodyClass ] = selected.name.split(' ');
        Array.from(document.body.classList)
        .forEach(row => {
            if (row.includes('golfclub-')) {
                document.body.classList.remove(row)
            }
        })
        document.body.classList.add(`golfclub-${ bodyClass.toLowerCase() }`)
        
        nextTick(async () => {
            const { golfClubId } = selected;
            
            dispatch('res_ecom/setEcomServiceSettings', serviceSettings.value)
            dispatch('res_golf/setGolfServiceSettings', serviceSettings.value)
            await dispatch("common/getMobileAppSettings")
            // trigger to get user player information
            dispatch('res_golf/getPlayerInformation', { reset: true });
            dispatch('res_golf/getClubInformation', { reset: true });
            
            commit('res_golf/setDefaultClubId', golfClubId);

            const { data } = await dispatch('res_ecom/getMyCart')
            
            if (Array.isArray(data.rows)) {
                const reservations = data.rows.filter((row) => row.type === 6);

                if (reservations.length > 0) {
                    if (data.orderId) {
                        commit('res_reservations/setDisabled', true)
                    }

                    ecomCartAlert(data);
                }
            }
        })
    }

    async function onClubSelectionChange(evt) {
        const [ selected ] = associatedClubs.value.filter(row => row.name === evt.target.value)
        const { appauth, urls, name, displayName } = selected;
        const old = selectedClub.value;
        const approveHandler = () => {
            selectedClub.value = evt.target.value;
            // overlay animation 300ms
            setTimeout(() => onClubSelection(), 310)
        }
        
        if (typeof evt.target.blur === 'function') {
            evt.target.blur()
        }

        if (selected.name === selectedHost.value) {
            store.commit('user/mutateOverrideHostUser', null);
            approveHandler();
            return;
        } else {
            loadingOverlay()
            // check if selected club has already consented (ajax rest endpoint)
            // if yes -> approveHandler
            // if no ask permission
            const { success, personId, userId } = await store.dispatch(
                'user/consentUser', 
                { checkConsent: true, name, appauth, ...urls }
            )
            consentLoading.dismiss()

            if (success) {
                store.commit('user/mutateOverrideHostUser', { personId, userId })
                approveHandler();
                return;
            }
        }
              
        wiseAlert({
            mode: 'ios',
            message: _locale(
                'golf.consentPermissionQuestion',
                true,
                displayName
            ),
            backdropDismiss: false,
            buttons: [
                {
                    text: _locale('cancel'),
                    handler: () => {
                        evt.target.value = old;
                    }
                },
                {
                    text: _locale('golf.readConsentManual'),
                    handler: () => {
                        Browser.open({ url: `${ urls.baseUrl }/tietosuojaseloste` });
                        return false;
                    }
                },
                {
                    text: _locale('accept'),
                    handler: async () => {
                        const { success, personId, userId } = await store.dispatch('user/consentUser', { name, appauth, ...urls });

                        if (success) {
                            store.commit('user/mutateOverrideHostUser', { personId, userId })
                            approveHandler();
                        } else {
                            evt.target.value = old;
                        }
                    }
                },
            ],
        })
    }

    async function golfInit() {
        const selected = await getClubMemory();
        
        if (selected) {
            setSelectedClub(selected)
        } else {
            const [ defaultOwner ] = associatedClubs.value.filter(row => row.name === selectedHost.value);
            
            setSelectedClub(defaultOwner?.name)
        }
        
        await checkConsent();
        await onClubSelection(false);
    }

    return {
        loggedIn,
        selectedHost,
        notificationsSettings,
        serviceSettings,
        adminMode,
        getClubMemory,
        setClubMemory,
        ecomCartAlert,
        onClubSelectionChange,
        onClubSelection,
        checkConsent,
        golfInit,
    }
}