import { version, versionDate, description } from '../package.json'

export default {
	hosts: [

		{
			appauth: 'ZEArxdAFCfXE7sSJ',
			name: 'wisegolfclub',
			domain: 'wisegolfclub.fi',
			baseUrl: 'https://wisegolfclub.fi',
			ajaxUrl: 'https://ajax.wisegolfclub.fi',
			restUrl: 'https://api.wisegolfclub.fi/api/1.0',
			ecomUrl: 'https://kauppa.wisegolfclub.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			displayName: 'WiseGolf vieraspelaajat',
			displayChainName: 'WiseGolf vieraspelaajat',
			golfClubId: 99999,
			className: 'wisegolf',
			registerMailingLists: [1],
			isDefault: true,
		},
		{
			appauth: 'ZEArxdAFCfXE7sSJ',
			name: 'wnw',
			domain: 'wnw.fi',
			baseUrl: 'https://wnw.fi',
			ajaxUrl: 'https://ajax.wnw.fi',
			restUrl: 'https://api.wnw.fi/api/1.0',
			ecomUrl: 'https://shop.wnw.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			displayName: 'WNW',
			displayChainName: "WNW",
			golfClubId: 99998,
			className: 'wisegolf',
			registerMailingLists: [1],
		},
		/*
		{
			appauth: 'nbw57EVhedb6hTQt',
			name: 'wisedemo10',
			domain: 'wisedemo10.fi',
			baseUrl: 'https://wisedemo10.fi',
			ajaxUrl: 'https://ajax.wisedemo10.fi',
			restUrl: 'https://api.wisedemo10.fi/api/1.0',
			ecomUrl: 'https://kauppa.wisedemo10.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 91,
			displayName: 'wisedemo10',
			displayChainName: "wisedemo10",
			className: 'wisegolf',
			registerMailingLists: [1],
			//disableRegistration: true,
		},
		{
			appauth: '9CNqY2ESJKEk2Bx',
			name: 'wiseverse',
			domain: 'wiseverse.fi',
			baseUrl: 'https://wiseverse.fi',
			ajaxUrl: 'https://ajax.wiseverse.fi',
			restUrl: 'https://api.wiseverse.fi/api/1.0',
			ecomUrl: 'https://kauppa.wiseverse.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 99999,
			displayName: 'Wiseverse',
			displayChainName: "Wiseverse",
			className: 'wisegolf',
			articleCategory: 'golf_app',
			registerMailingLists: [1],
		},
		*/
		/*
		{
			appauth: 'xQ8Uyf5yBL8zL6Dg',
			name: 'wisenetwork',
			domain: 'wisenetwork.fi',
			baseUrl: 'https://wisenetwork.fi',
			// for news, this works fine
			// at some point app will show wisegolf news for not logged in users
			ajaxUrl: 'https://ajax.wisegolf.fi',
			restUrl: 'https://api.wisenetwork.fi/api/1.0',
			ecomUrl: 'https://kauppa.wisenetwork.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			displayName: 'WiseGolf',
			displayChainName: "WiseGolf",
			className: 'wisegolf',
			registerMailingLists: [1],
			// since wisenetwork is still not in cloud
			disableLogin: true,
			disableGuestSignOn: true,
			disableRegistration: true,
		},
		*/
		{
			appauth: 'ums7DCgz4NevpmCK',
			name: 'yyterigolf',
			domain: 'yyterigolf.fi',
			baseUrl: 'https://yyterigolf.fi',
			ajaxUrl: 'https://ajax.yyterigolf.fi',
			restUrl: 'https://api.yyterigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.yyterigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 91,
			displayName: 'Yyteri Golf',
			displayChainName: "Yyteri Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'St5sZdngQfWg6BUd',
			name: 'kalafornia',
			domain: 'kalafornia.com',
			baseUrl: 'https://kalafornia.com',
			ajaxUrl: 'https://ajax.kalafornia.com',
			restUrl: 'https://api.kalafornia.com/api/1.0',
			ecomUrl: 'https://kauppa.kalafornia.com',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 59,
			displayName: 'Porin Golfkerho',
			displayChainName: "Porin Golfkerho",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'ftrX9FX2uCj39hCc',
			name: 'hyvigolf',
			domain: 'hyvigolf.fi',
			baseUrl: 'https://hyvigolf.fi',
			ajaxUrl: 'https://ajax.hyvigolf.fi',
			restUrl: 'https://api.hyvigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.hyvigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 18,
			displayName: 'Hyvinkään Golf',
			displayChainName: "Hyvinkään Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'yGtDEhu3eHX9bLzM',
			name: 'kankaistengolf',
			domain: 'kgm.fi',
			baseUrl: 'https://kgm.fi',
			ajaxUrl: 'https://ajax.kgm.fi',
			restUrl: 'https://api.kgm.fi/api/1.0',
			ecomUrl: 'https://kauppa.kgm.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 62,
			displayName: 'Kankaisten Golf',
			displayChainName: "Kankaisten Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'NRGsZdngQfWg6St5',
			name: 'rivergolf',
			domain: 'nokiarivergolf.fi',
			baseUrl: 'https://nokiarivergolf.fi',
			ajaxUrl: 'https://ajax.nokiarivergolf.fi',
			restUrl: 'https://api.nokiarivergolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.nokiarivergolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 66,
			displayName: 'Nokia River Golf',
			displayChainName: "Nokia River Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},

		{
			appauth: 'HhgtsfzvVdgZTnQC',
			name: 'jarviseudungolf',
			domain: 'jgs.fi',
			baseUrl: 'https://jgs.fi',
			ajaxUrl: 'https://ajax.jgs.fi',
			restUrl: 'https://api.jgs.fi/api/1.0',
			ecomUrl: 'https://kauppa.jgs.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 23,
			displayName: 'Järviseudun Golfseura',
			displayChainName: "Järviseudun Golfseura",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'K3NrrYd86Zn9Xjny',
			name: 'iittigolf',
			domain: 'iittigolf.com',
			baseUrl: 'https://iittigolf.com',
			ajaxUrl: 'https://ajax.iittigolf.com',
			restUrl: 'https://api.iittigolf.com/api/1.0',
			ecomUrl: 'https://kauppa.iittigolf.com',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 105,
			displayName: 'Iitin Golfseura',
			displayChainName: "Iitin Golfseura",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'BZSEuePVm5624Tyf',
			name: 'shg',
			domain: 'shg.fi',
			baseUrl: 'https://shg.fi',
			ajaxUrl: 'https://ajax.shg.fi',
			restUrl: 'https://api.shg.fi/api/1.0',
			ecomUrl: 'https://kauppa.shg.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 77,
			displayName: 'Suur-Helsingin Golf',
			displayChainName: "Suur-Helsingin Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'Fk57AHPBLzAzc9rs',
			name: 'hartolagolf',
			domain: 'hartolagolf.com',
			baseUrl: 'https://hartolagolf.com',
			ajaxUrl: 'https://ajax.hartolagolf.com',
			restUrl: 'https://api.hartolagolf.com/api/1.0',
			ecomUrl: 'https://kauppa.hartolagolf.com',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 14,
			displayName: 'Hartolan Golfklubi',
			displayChainName: "Hartolan Golfklubi",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgfcbcWnKG',
			name: 'Kytajagolf',
			domain: 'kytajagolf.fi',
			baseUrl: 'https://kytajagolf.fi',
			ajaxUrl: 'https://ajax.kytajagolf.fi',
			restUrl: 'https://api.kytajagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kytajagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 108,
			displayName: 'Kytäjä Golf',
			displayChainName: "Kytäjä Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'HhgtsfzvVdgZTnQC',
			name: 'keimolagolf',
			domain: 'keimolagolf.com',
			baseUrl: 'https://keimolagolf.com',
			ajaxUrl: 'https://ajax.keimolagolf.com',
			restUrl: 'https://api.keimolagolf.com/api/1.0',
			ecomUrl: 'https://kauppa.keimolagolf.com',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 84,
			displayName: 'Keimola Golf',
			displayChainName: "Keimola Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgKANWnasd',
			name: 'kanavagolf',
			domain: 'kanavagolf.com',
			baseUrl: 'https://kanavagolf.com',
			ajaxUrl: 'https://ajax.kanavagolf.com',
			restUrl: 'https://api.kanavagolf.com/api/1.0',
			ecomUrl: 'https://kauppa.kanavagolf.com',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 115,
			displayName: 'Kanavagolf Vääksy',
			displayChainName: "Kanavagolf Vääksy",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgfcbWnTGK',
			name: 'tgk',
			domain: 'tgk.fi',
			baseUrl: 'https://tgk.fi',
			ajaxUrl: 'https://ajax.tgk.fi',
			restUrl: 'https://api.tgk.fi/api/1.0',
			ecomUrl: 'https://kauppa.tgk.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 80,
			displayName: 'Tuusulan Golfklubi',
			displayChainName: "Tuusulan Golfklubi",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'HhgtsfzvVdgZTnQee',
			name: 'ringsidegolf',
			domain: 'ringsidegolf.fi',
			baseUrl: 'https://ringsidegolf.fi',
			ajaxUrl: 'https://ajax.ringsidegolf.fi',
			restUrl: 'https://api.ringsidegolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.ringsidegolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 7,
			displayName: 'Espoo Ringside Golf',
			displayChainName: "Espoo Ringside Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'ums7DCgz4NevpmCK',
			name: 'raumagolf',
			domain: 'raumagolf.fi',
			baseUrl: 'https://raumagolf.fi',
			ajaxUrl: 'https://ajax.raumagolf.fi',
			restUrl: 'https://api.raumagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.raumagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 63,
			displayName: 'Rauma Golf',
			displayChainName: "Rauma Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'LaksZdngQfWg6St5',
			name: 'lakesidegolf',
			domain: 'lakesidegolf.fi',
			baseUrl: 'https://lakesidegolf.fi',
			ajaxUrl: 'https://ajax.lakesidegolf.fi',
			restUrl: 'https://api.lakesidegolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.lakesidegolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 83,
			displayName: 'Lakeside Golf Vammala',
			displayChainName: "Lakeside Golf Vammala",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmuu2DgdsaWnasd',
			name: 'muuramegolf',
			domain: 'muuramegolf.fi',
			baseUrl: 'https://muuramegolf.fi',
			ajaxUrl: 'https://ajax.muuramegolf.fi',
			restUrl: 'https://api.muuramegolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.muuramegolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 64,
			displayName: 'Muuramen Golfseura',
			displayChainName: "Muuramen Golfseura",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'k4RHsj8xSswyMk6J',
			name: 'golfpirkkala',
			domain: 'golfpirkkala.fi',
			baseUrl: 'https://golfpirkkala.fi',
			ajaxUrl: 'https://ajax.golfpirkkala.fi',
			restUrl: 'https://api.golfpirkkala.fi/api/1.0',
			ecomUrl: 'https://kauppa.golfpirkkala.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 104,
			displayName: 'Golf Pirkkala',
			displayChainName: "Golf Pirkkala",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'ums7DCgz4NagExFK',
			name: 'tahkogolf',
			domain: 'tahkogolf.fi',
			baseUrl: 'https://tahkogolf.fi',
			ajaxUrl: 'https://ajax.tahkogolf.fi',
			restUrl: 'https://api.tahkogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.tahkogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 47,
			displayName: 'Tahko Golf',
			displayChainName: "Tahko Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'ums7DCgz4rttvpmCf',
			name: 'nordcenter',
			domain: 'nordcenter.fi',
			baseUrl: 'https://nordcenter.fi',
			ajaxUrl: 'https://ajax.nordcenter.fi',
			restUrl: 'https://api.nordcenter.fi/api/1.0',
			ecomUrl: 'https://kauppa.nordcenter.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 48,
			displayName: 'Nordcenter Golf & Country Club',
			displayChainName: "Nordcenter Golf & Country Club",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgfOGcWnKG',
			name: 'oulugolf',
			domain: 'oulugolf.fi',
			baseUrl: 'https://oulugolf.fi',
			ajaxUrl: 'https://ajax.oulugolf.fi',
			restUrl: 'https://api.oulugolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.oulugolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 50,
			displayName: 'Oulun Golfkerho',
			displayChainName: "Oulun Golfkerho",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgKUbcWnKG',
			name: 'kullogolf',
			domain: 'kullogolf.fi',
			baseUrl: 'https://kullogolf.fi',
			ajaxUrl: 'https://ajax.kullogolf.fi',
			restUrl: 'https://api.kullogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kullogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 107,
			displayName: 'Kullo Golf Club',
			displayChainName: "Kullo Golf Club",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'ums7DCgz4NevpmCK',
			name: 'tammergolf',
			domain: 'tammer-golf.fi',
			baseUrl: 'https://tammer-golf.fi',
			ajaxUrl: 'https://ajax.tammer-golf.fi',
			restUrl: 'https://api.tammer-golf.fi/api/1.0',
			ecomUrl: 'https://kauppa.tammer-golf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 78,
			displayName: 'Tammer-Golf',
			displayChainName: "Tammer-Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'Dkh9jsdGGNFyNrcU',
			name: 'linnagolf',
			domain: 'linnagolf.fi',
			baseUrl: 'https://linnagolf.fi',
			ajaxUrl: 'https://ajax.linnagolf.fi',
			restUrl: 'https://api.linnagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.linnagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 116,
			displayName: 'Vanajanlinna Golf & Country Club',
			displayChainName: "Vanajanlinna Golf & Country Club",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'J5mYZecGUX4LPGwW',
			name: 'peurunkagolf',
			domain: 'lpg.fi',
			baseUrl: 'https://lpg.fi',
			ajaxUrl: 'https://ajax.lpg.fi',
			restUrl: 'https://api.lpg.fi/api/1.0',
			ecomUrl: 'https://kauppa.lpg.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 37,
			displayName: 'Laukaan Peurunkagolf',
			displayChainName: "Laukaan Peurunkagolf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rfnIndemidbeIEdbhfEuh',
			name: 'kurkgolf',
			domain: 'kurkgolf.fi',
			baseUrl: 'https://kurkgolf.fi',
			ajaxUrl: 'https://ajax.kurkgolf.fi',
			restUrl: 'https://api.kurkgolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kurkgolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 33,
			displayName: 'Kurk Golf',
			displayChainName: 'Kurk Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rfnIndemidbeIEdbhfEuh',
			name: 'smartgolf',
			domain: 'kurkgolf.fi',
			baseUrl: 'https://kurkgolf.fi',
			ajaxUrl: 'https://ajax.kurkgolf.fi',
			restUrl: 'https://api.kurkgolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kurkgolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 145,
			displayName: 'Smart Golf Ry',
			displayChainName: 'Smart Golf Ry',
			articleCategory: 'hirvensalongolf_app',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'cMLTfNeMRNvrRC6K',
			name: 'peuramaagolf',
			domain: 'peuramaagolf.fi',
			baseUrl: 'https://peuramaagolf.fi',
			ajaxUrl: 'https://ajax.peuramaagolf.fi',
			restUrl: 'https://api.peuramaagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.peuramaagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 54,
			displayName: 'Peuramaa Golf Hjortlandet',
			displayChainName: "Peuramaa Golf Hjortlandet",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'cMLTfNeMRNvHIL6K',
			name: 'hillside',
			domain: 'hillside.fi',
			baseUrl: 'https://hillside.fi',
			ajaxUrl: 'https://ajax.hillside.fi',
			restUrl: 'https://api.hillside.fi/api/1.0',
			ecomUrl: 'https://kauppa.hillside.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 110,
			displayName: 'Hill Side Golf',
			displayChainName: "Hill Side Golf",
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'C3gRnGcoUxGtF9rC',
			name: 'ruuhikoskigolf',
			domain: 'ruuhikoskigolf.fi',
			baseUrl: 'https://ruuhikoskigolf.fi',
			ajaxUrl: 'https://ajax.ruuhikoskigolf.fi',
			restUrl: 'https://api.ruuhikoskigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.ruuhikoskigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 8,
			displayName: 'Ruuhikoski Golf Seinäjoki',
			displayChainName: 'Ruuhikoski Golf Seinäjoki',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'IKETChKosAh6Pahh',
			name: 'koskigolf',
			domain: 'koskigolf.fi',
			baseUrl: 'https://koskigolf.fi',
			ajaxUrl: 'https://ajax.koskigolf.fi',
			restUrl: 'https://api.koskigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.koskigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 32,
			displayName: 'Koskigolf',
			displayChainName: 'Koskigolf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		/*
		{
			appauth: 'xQ8Uyf5yKOTzL6Dg',
			name: 'kotojarvengolf',
			domain: 'kotojarvigolf.fi',
			baseUrl: 'https://kotojarvigolf.fi',
			ajaxUrl: 'https://ajax.kotojarvigolf.fi',
			restUrl: 'https://api.kotojarvigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kotojarvigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 137,
			displayName: 'Kotojärvi Golf',
			displayChainName: 'Kotojärvi Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		*/
		{
			appauth: 'wHdsEqSGUMcznzYB',
			name: 'gumbolegolf',
			domain: 'espoogolf.fi',
			baseUrl: 'https://espoogolf.fi',
			ajaxUrl: 'https://ajax.espoogolf.fi',
			restUrl: 'https://api.espoogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.espoogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 12,
			displayName: 'Gumböle Golf',
			displayChainName: 'Gumböle Golf',
			className: 'wisegolf',
			articleCategory: 'gumbole_app',
			registerMailingLists: [1]
		},
		{
			appauth: 'wHdsEqSGUMcznzYB',
			name: 'espoogolf',
			domain: 'espoogolf.fi',
			baseUrl: 'https://espoogolf.fi',
			ajaxUrl: 'https://ajax.espoogolf.fi',
			restUrl: 'https://api.espoogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.espoogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 6,
			displayName: 'Espoon Golfseura',
			displayChainName: 'Espoon Golfseura',
			className: 'wisegolf',
			articleCategory: 'espoogolf_app',
			registerMailingLists: [1]
		},
		{
			appauth: 'ARzexdAFVigs7sJS',
			name: 'vierumakigolf',
			domain: 'vierumakigolf.fi',
			baseUrl: 'https://vierumakigolf.fi',
			ajaxUrl: 'https://ajax.vierumakigolf.fi',
			restUrl: 'https://api.vierumakigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.vierumakigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 86,
			displayName: 'Vierumäki Golf',
			displayChainName: 'Vierumäki Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDekmdediNDdEdbhfEuh',
			name: 'hattulagolf',
			domain: 'hattulagolf.fi',
			baseUrl: 'https://hattulagolf.fi',
			ajaxUrl: 'https://ajax.hattulagolf.fi',
			restUrl: 'https://api.hattulagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.hattulagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 109,
			displayName: 'Hattula Golf',
			displayChainName: 'Hattula Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rfnEDinmEojdEdbhfEuh',
			name: 'paltamogolf',
			domain: 'paltamogolf.fi',
			baseUrl: 'https://paltamogolf.fi',
			ajaxUrl: 'https://ajax.paltamogolf.fi',
			restUrl: 'https://api.paltamogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.paltamogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 52,
			displayName: 'Paltamo Golf',
			displayChainName: 'Paltamo Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'riomsnneidbeIEdbhfEuh',
			name: 'kajaaningolf',
			domain: 'kajaaningolf.fi',
			baseUrl: 'https://kajaaningolf.fi',
			ajaxUrl: 'https://ajax.kajaaningolf.fi',
			restUrl: 'https://api.kajaaningolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kajaaningolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 24,
			displayName: 'Kajaanin Golf',
			displayChainName: 'Kajaanin Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfEuh',
			name: 'virvikgolf',
			domain: 'virvikgolf.fi',
			baseUrl: 'https://virvikgolf.fi',
			ajaxUrl: 'https://ajax.virvikgolf.fi',
			restUrl: 'https://api.virvikgolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.virvikgolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 88,
			displayName: 'Virvik Golf',
			displayChainName: 'Virvik Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfEuh',
			name: 'puulagolf',
			domain: 'puulagolf.fi',
			baseUrl: 'https://puulagolf.fi',
			ajaxUrl: 'https://ajax.puulagolf.fi',
			restUrl: 'https://api.puulagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.puulagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 120,
			displayName: 'PuulaGolf',
			displayChainName: 'PuulaGolf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfEuh',
			name: 'kareliagolf',
			domain: 'kareliagolf.fi',
			baseUrl: 'https://kareliagolf.fi',
			ajaxUrl: 'https://ajax.kareliagolf.fi',
			restUrl: 'https://api.kareliagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kareliagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 26,
			displayName: 'Karelia Golf',
			displayChainName: 'Karelia Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rjsnnneidbeIEdbhfEuh',
			name: 'hirsalagolf',
			domain: 'hirsalagolf.fi',
			baseUrl: 'https://hirsalagolf.fi',
			ajaxUrl: 'https://ajax.hirsalagolf.fi',
			restUrl: 'https://api.hirsalagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.hirsalagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 111,
			displayName: 'Hirsala Golf',
			displayChainName: 'Hirsala Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'erkmDEikmzL6Dg',
			name: 'pielisgolf',
			domain: 'pielisgolf.fi',
			baseUrl: 'https://pielisgolf.fi',
			ajaxUrl: 'https://ajax.pielisgolf.fi',
			restUrl: 'https://api.pielisgolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.pielisgolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 56,
			displayName: 'Pielis-Golf',
			displayChainName: 'Pielis-Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: '38nDWkdfmeidf)SvAQw',
			name: 'hirvihaara',
			domain: 'hirvihaarangolf.fi',
			baseUrl: 'https://hirvihaarangolf.fi',
			ajaxUrl: 'https://ajax.hirvihaarangolf.fi',
			restUrl: 'https://api.hirvihaarangolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.hirvihaarangolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 118,
			displayName: 'Hirvihaaran Golf Mäntsälä',
			displayChainName: 'Hirvihaaran Golf Mäntsälä',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rjsnAseidDOsICdbhfEuh',
			name: 'nevasgolf',
			domain: 'nevasgolf.fi',
			baseUrl: 'https://nevasgolf.fi',
			ajaxUrl: 'https://ajax.nevasgolf.fi',
			restUrl: 'https://api.nevasgolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.nevasgolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 46,
			displayName: 'Nevas Golf',
			displayChainName: 'Nevas Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: '4rk49mnfkfrOsICdbhfEuh',
			name: 'aurinkogolf',
			domain: 'aurinkogolf.fi',
			baseUrl: 'https://aurinkogolf.fi',
			ajaxUrl: 'https://ajax.aurinkogolf.fi',
			restUrl: 'https://api.aurinkogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.aurinkogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 117,
			displayName: 'Aurinko Golf',
			displayChainName: 'Aurinko Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfUGK',
			name: 'ugk',
			domain: 'ugk.fi',
			baseUrl: 'https://ugk.fi',
			ajaxUrl: 'https://ajax.ugk.fi',
			restUrl: 'https://api.ugk.fi/api/1.0',
			ecomUrl: 'https://kauppa.ugk.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 81,
			displayName: 'Uudenkaupungin Golfklubi',
			displayChainName: 'Uudenkaupungin Golfklubi',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rejnneidbeIEdbhfEuh',
			name: 'seagolf',
			domain: 'seagolf.fi',
			baseUrl: 'https://seagolf.fi',
			ajaxUrl: 'https://ajax.seagolf.fi',
			restUrl: 'https://api.seagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.seagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 72,
			displayName: 'Sea Golf Rönnäs',
			displayChainName: 'Sea Golf Rönnäs',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfKAR',
			name: 'kartanogolf',
			domain: 'kartanogolf.fi',
			baseUrl: 'https://kartanogolf.fi',
			ajaxUrl: 'https://ajax.kartanogolf.fi',
			restUrl: 'https://api.kartanogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kartanogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 27,
			displayName: 'Kartanogolf',
			displayChainName: 'Kartanogolf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfEuh',
			name: 'nurmijarvigolf',
			domain: 'nurmijarvigolf.fi',
			baseUrl: 'https://nurmijarvigolf.fi',
			ajaxUrl: 'https://ajax.nurmijarvigolf.fi',
			restUrl: 'https://api.nurmijarvigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.nurmijarvigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 49,
			displayName: 'Nurmijärvi Golf',
			displayChainName: 'Nurmijärvi Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfEuh',
			name: 'wgcc',
			domain: 'wgcc.fi',
			baseUrl: 'https://wgcc.fi',
			ajaxUrl: 'https://ajax.wgcc.fi',
			restUrl: 'https://api.wgcc.fi/api/1.0',
			ecomUrl: 'https://kauppa.wgcc.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 89,
			displayName: 'Wiurila Golf & Country Club',
			displayChainName: 'Wiurila Golf & Country Club',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjnneidbeIEdbhfOGS',
			name: 'ogs',
			domain: 'ogs.fi',
			baseUrl: 'https://ogs.fi',
			ajaxUrl: 'https://ajax.ogs.fi',
			restUrl: 'https://api.ogs.fi/api/1.0',
			ecomUrl: 'https://kauppa.ogs.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 51,
			displayName: 'Outokummun Golfseura',
			displayChainName: 'Outokummun Golfseura',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'AjDjnngtypeIcdbhfSul',
			name: 'mikkelingolf',
			domain: 'mikkelingolf.fi',
			baseUrl: 'https://mikkelingolf.fi',
			ajaxUrl: 'https://ajax.mikkelingolf.fi',
			restUrl: 'https://api.mikkelingolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.mikkelingolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 45,
			displayName: 'Mikkelin Golf',
			displayChainName: 'Mikkelin Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'EjDjkleidbeILdaghfEuh',
			name: 'kalajokigolf',
			domain: 'kalajokigolf.fi',
			baseUrl: 'https://kalajokigolf.fi',
			ajaxUrl: 'https://ajax.kalajokigolf.fi',
			restUrl: 'https://api.kalajokigolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kalajokigolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 25,
			displayName: 'Kalajoki Golf',
			displayChainName: 'Kalajoki Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'JWnowdbeIEdbhfEuh',
			name: 'eerikkalagolf',
			domain: 'eerikkalagolf.fi',
			baseUrl: 'https://eerikkalagolf.fi',
			ajaxUrl: 'https://ajax.eerikkalagolf.fi',
			restUrl: 'https://api.eerikkalagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.eerikkalagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 106,
			displayName: 'Eerikkala Golf',
			displayChainName: 'Eerikkala Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'kfmro/h32onIEdbhfEuh',
			name: 'anolagolf',
			domain: 'anolagolf.fi',
			baseUrl: 'https://anolagolf.fi',
			ajaxUrl: 'https://ajax.anolagolf.fi',
			restUrl: 'https://api.anolagolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.anolagolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 1,
			displayName: 'Anola Golf',
			displayChainName: 'Anola Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rjnsjnmJddbeIEdbhfEuh',
			name: 'kymengolf',
			domain: 'kymengolf.fi',
			baseUrl: 'https://kymengolf.fi',
			ajaxUrl: 'https://ajax.kymengolf.fi',
			restUrl: 'https://api.kymengolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.kymengolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 35,
			displayName: 'Kymen Golf',
			displayChainName: 'Kymen Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		/*
		{
			appauth: 'ejmnOIejdbeIEdbhfEuh',
			name: 'imatrangolf',
			domain: 'imatrangolf.fi',
			baseUrl: 'https://imatrangolf.fi',
			ajaxUrl: 'https://ajax.imatrangolf.fi',
			restUrl: 'https://api.imatrangolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.imatrangolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 20,
			displayName: 'Imatran Golf',
			displayChainName: 'Imatran Golf',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		*/
		{
			appauth: 'JKLniIejdalDihbhfEfa',
			name: 'torniogolf',
			domain: 'torniogolf.fi',
			baseUrl: 'https://torniogolf.fi',
			ajaxUrl: 'https://ajax.torniogolf.fi',
			restUrl: 'https://api.torniogolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.torniogolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 42,
			displayName: 'Meri-Lapin Golfklubi',
			displayChainName: 'Meri-Lapin Golfklubi',
			className: 'wisegolf',
			registerMailingLists: [1]
		},
		{
			appauth: 'rdmdy2DgfcbcWpTB',
			name: 'seasongolf',
			domain: 'seasongolf.fi',
			baseUrl: 'https://seasongolf.fi',
			ajaxUrl: 'https://ajax.seasongolf.fi',
			restUrl: 'https://api.seasongolf.fi/api/1.0',
			ecomUrl: 'https://kauppa.seasongolf.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 99991,
			displayName: 'Season Golf',
			displayChainName: 'Season Golf',
			className: 'wisegolf',
			articleCategory: 'wgapp',
			registerMailingLists: [1],
			isNonClub: 1,
			disableGuestSignOn: true,
		},
		{
			appauth: '5TmX0A8F70MASvAQw',
			name: 'oulungolfhalli',
			domain: 'oulungolfhalli.fi',
			baseUrl: 'https://oulungolfhalli.fi',
			ajaxUrl: 'https://ajax.oulungolfhalli.fi',
			restUrl: 'https://api.oulungolfhalli.fi/api/1.0',
			ecomUrl: 'https://kauppa.oulungolfhalli.fi',
			useRestLogin: true,
			sessionType: 'wisegolf',
			golfClubId: 99992,
			displayName: 'Oulun golfhalli',
			displayChainName: 'Oulun golfhalli',
			className: 'wisegolf',
			articleCategory: 'wgapp',
			registerMailingLists: [1],
			isNonClub: 1,
			disableGuestSignOn: true,
		},
	].sort((a, b) => {
		if (a.isDefault) return -1;
        if (b.isDefault) return 1;
		if (a.isNonClub) return 1;
        if (b.isNonClub) return -1;

		return a.displayName > b.displayName ? 1 : -1;
	}).map(row => {
		try {
			const ecomUrl = new URL(row.ecomUrl)

			let [ subdomain, domain, ending ] = ecomUrl.hostname.split('.')

			subdomain = 'admin'

			row.adminUrl = `${ ecomUrl.protocol }//${ subdomain }.${ domain }.${ ending }`
		} catch(e) {
			console.log(e, row)
		}
		
		return row;
	}),
	appId: "affbfa03",
	appVersion: `${ description } ${ version } - ${ versionDate }`, //This is just for error log. Keep the format of "version - date". Its important.
	version,
	bluetoothFallbackLocale: "fi-FI", // If some data not found in user choosed locale fallback to this locale. (Used door name, on door open)
	fallbackLocale: 'fi',
	defaultClassName: 'wisegolf',
	defaultScreenMode: 'dark',

	multipurposeAccessRightEnabled: ['wisedemo10','wnw','espoogolf','gumbolegolf','golfpirkkala','koskigolf','vierumakigolf','tgk','kankaistengolf'],
	guestPlayerRegistrationHostName: 'wisegolfclub',
	//guestPlayerRegistrationHostName: 'wnw',

	// app features
	useGymFeatures: false,
	useGolfFeatures: true,
	useReservationWebsocket: false,

	// Register form
	usePersonDateOfBirth: true, // If true collects person birthday on register
	usePersonGender: true,
	usePersonGuardian: false, // If true collects guardianName guardianBirthday on register (To use guradian settings usePersonDateOfBirth have to set true)
	usePersonGuardianContacts: true, // If true collects guardianPhone ja guardianEmail on register (To use guradian settings usePersonDateOfBirth have to set true)
	usePersonAdressInformation: true, // If true collects streetAddress, postalCode and city on register
	minAgeToRegister: 0, // User has to be at least years old to set own birthday on register form
	disableRegistrationClubs: ['99999', '99998'], // '99999', '99998'
	// app default path, comment out to use "demo mode"

	defaultPath: {
		path: '/',
		redirect: '/golf/front'
	},

	loginModalHostText: 'loginModal.golfClub',
	loginModalSelectText: 'loginModal.chooseGolfClub',

	registerModalHostText: 'loginModal.golfClub',
	registerModalSelectText: 'loginModal.chooseGolfClub',

	noHostSelectedText: 'errors.loginError.noClubSelected',

	// PushNotifications
	hideNotificationSettings: false,
	// topics can be overwritten by getmobileappsettings endpoint
	// generate topics by host (WiseGolf feature)
	get topics() {
		const topics = {
			'wisegolf-general-fi': {
				label: 'WiseGolf uutiset'
			},
			'wisegolf-general-en': {
				label: 'WiseGolf news'
			},
			'wisegolf-general-sv': {
				label: 'WiseGolf nyheter'
			},
		}

		const dictionary = {
			fi: {
				news: 'uutiset',
				offers: 'tarjoukset'
			},
			en: {
				news: 'news',
				offers: 'offers'
			},
			sv: {
				news: 'nyheter',
				offers: 'budgivning'
			}
		}

		for(let host of this.hosts) {
			for (let langCode of Object.keys(dictionary)) {
				const lang = dictionary[langCode]
				const newsKey = `${ host.name }-news-${ langCode }`
				const newsLabel = `${ host.displayName } ${ lang.news }`
				const offersKey = `${ host.name }-offers-${ langCode }`
				const offersLabel = `${ host.displayName } ${ lang.offers }`
				
				topics[newsKey] = { label: newsLabel };
				topics[offersKey] = { label: offersLabel };
			}					
		}

		return topics;
	},
	get topicDefaults() {
		const topics = {
			'wisegolf-news-fi': false,
			'wisegolf-news-en': false,
			'wisegolf-news-sv': false
		}
		const langs = ['fi','en','sv']
		for(let host of this.hosts) {
			for (let langCode of langs) {
				const newsKey = `${ host.name }-news-${ langCode }`
				const offersKey = `${ host.name }-offers-${ langCode }`
				
				topics[newsKey] = false;
				topics[offersKey] = false;
			}			
		}

		return topics;
	},
	notificationRedirects: {
		news: '/golf/today/article/', // default: today/article/
	},
	/*
	topics: {
		"news-en": {
			label: 'General news',
		},
		"news-fi": {
			label: 'Yleiset uutiset',
		},
	},
	topicDefaults: {
		"news-fi": true,
	},
	
	*/
	// EXAMPLE Push notifications topics using locale: Put this to database --> MobileAppSettins name = topics
	// {
	// 	"news-fi": {
	// 		"label": "Yleiset uutiset"
	// 	},
	// 	"news-en": {
	// 		"label": "General news"
	// 	},
	// 	"news-sv": {
	// 		"label": "Allmänna nyheter"
	// 	},
	// 	"fitnessnews-fi": {
	// 		"label": "Fitness news"
	// 	},
	// 	"fitnessnews-en": {
	// 		"label": "Group exercise news"
	// 	},
	// 	"fitnessnews-sv": {
	// 		"label": "Gruppträningsnyheter"
	// 	},
	// }

	// GYM Event confirm
	eventEnroll: {
		startBeforeMinutes: 120, //How long before event start can we enroll?
		stopBeforeMinutes: 0 //Does enrollment stop sometime before event start?
	},
	allowEnrollConfirmCancelBeforeMinutes: 10, //Do we allow cancel of enrollment/confirmation once start is less than this. True/undefined means no restriction. False means cancel is never allowed.
	eventConfirm: {
		startBeforeMinutes: 995, //How long before event start can we confirm?
		// autoConfirmWithinMinutes: true, //True means match normal confirmation. False means never autoconfirm. Value means that many minutes. Only on enroll!
		requireLocationCheck: true, //On true require door open within ifDoorOpenedInLastMinutes or valid GPS. On false freely confirm
		ifDoorOpenedInLastMinutes: 60,
		allowConfirmCancel: true, //Is it allowed to cancel confirmations.
		autoConfirmOnEntryReaderId: false
		//autoConfirmOnEntryReaderId: 2 //When user enters this door, autoconfirm unregistered entries within X minutes.
	},
	// FROM FITNESS ENDPOINT UNDER CATEGORIES enrollUntilEndCategoryId: null, //For this category, allow enrollment until activityEndTime. For Lapsiparkki.
	fitnessEnrollmentEnabled: true,
	refreshEventDataMinutes: 10,
	fitnessConfirmationEnabled: true,
  // Validity information
	validityInformation: {
		alwaysShowValidity: false, // Show message about permanent access right
		showRoleValidity: true
	},
	// Hoax Open
	openDoorOnLocationFailure: true, //Are we using hoax open? Fakes valid open on 2nd gps/beacon failure. Only if gps/bluetooth available&authorized.
	maxTotalLocationFailures: 1, // How may times door open fails, before Hoax open.
	doorOpenLocationFailureHoaxInsideSecods: 60, // If maxTotalLocationFailures happens in this time do Hoax open.
	doorOpenLocationFailureHoaxWaitMsec: 2500, // How many milliseconds are delayed before the Hoax open is started.
	// Check-In
	onCheckInValidateLocation: false, // If true user location will be validated on check-in as normally on door open (GPS/BEACON)
	// Door open
	updatePositionAtBackgroundIntervalSeconds: 30, // How often position is updated on background when app is open.
	locationSearchTimeout: 45000,
	reuseLocationConfirmationLastMinutes: 2, // If succesful door open, how long user can re-use same validation, without needed to do confirm location
	validateLocationInsideDevLocation: true, // If true location for door open can validate from devLocation coordinates and from locationId coordinates / gym event confirm inside dec location
	// Debug
	debug: false, // Show debug information (for example door open)
	vuexLogger: false, // Vuex logger logs actions, mutations in console. (Helps debug code), (Vue-devtools chrome extension at the moment still beta for Vue 3) https://github.com/vuejs/devtools/releases
	devLocation: [ //'Wisenetwork Oy'
		{ lat: 61.4941388770538, lng: 21.796746253967285 },
		{ lat: 61.4941388770538, lng: 21.80172711610794 },
		{ lat: 61.492005080087296, lng: 21.80172711610794 },
		{ lat: 61.492005080087296, lng: 21.796746253967285 }
	]
};
